import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ContactForm from '../components/estimate-form';

export const StartWithAFreeQuote = props => (
	<div className="container mb-4 max-w-7xl mx-auto px-4">
		<div className="grid grid-cols-12 rounded-lg m-0 overflow-hidden shadow-lg bg-white">
			<div
				className={`col-span-12 md:col-span-6 flex-row items-center p-8 mb-4 sidebarform cardborder`}
			>
				<h2 className="text-center">Start with a Consultation!</h2>
				<ContactForm
					showServicesList={true}
					showLabels={true}
					showPlaceHolders={true}
					projects
					pageLocation={
						props.location.href
							? props.location.href
							: 'Not provided'
					}
				/>
			</div>
			<div
				className={`col-span-12 md:col-span-6 p-0 flex flex-row items-center`}
			>
				
				<img
					src="https://images.ctfassets.net/nv9cfugco4g8/LXTJgQXsOgiZND5ObtM26/c4dfa244d16389f473ef27708491919d/gvd-siding-826_Stillson_Drive-2.jpg"
					alt=""
					className="block w-full h-full object-cover"
				/>
			</div>
		</div>
	</div>
);
