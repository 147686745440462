import React, { useState, useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { ChevronRight } from 'react-feather';
import clsx from 'clsx';

export const TestimonialVideo = ({
	id,
	title,
	showTitle,
	thumbnail,
	className,
	video,
	columnsClassName,
	AfterTitleComponent,
	AfterImageComponent,
	slug,
	projectReference,
	rounded,
	projectsPage,
	responsive
}) => {
	const videoElement = useRef();
	const [isOpen, updateIsOpen] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);

	const playVideo = () => {
		updateIsOpen(true);
		try {
			const video = videoElement.current;
			video.play();
			video.onplaying = () => setIsPlaying(true); // Set playing state when video starts
		} catch (e) {
			console.log(
				'error playing video',
				'browser may not support media API'
			);
		}
	};

	return (
		<div
			key={id}
			className="col-span-12 sm:col-span-6 lg:col-span-4 lg:p-3 flex-row items-center mb-8 lg:pb-1 mt-0 pt-0"
		>
			{showTitle && (
				<h4 className="block mt-4 h-12 text-center font-bold leading-5 text-lg">
					{video && video.title}
				</h4>
			)}
			<div className="shadow-md bg-white rounded-xl">
				<div className="relative" key={id}>
					{!isOpen && (
						<span
							className="absolute w-full h-full play-overlay m-auto block rounded-t-xl"
							style={{
								zIndex: 10,
								cursor: 'pointer',
								top: '-100%',
								bottom: '-100%'
							}}
							onClick={playVideo}
						>
							<span className="play-button-overlay block">
								<img
									src="/assets/play.svg"
									onClick={playVideo}
									alt="Play Video"
									loading="lazy"
								/>
							</span>
						</span>
					)}
					<GatsbyImage
						image={getImage(thumbnail)}
						imgClassName={clsx({
							'rounded-t-xl mb-0 transition-opacity duration-1000': true,
							'opacity-0': isPlaying,
							'opacity-100': !isPlaying
						})}
						alt={`${video && video.title} Video Testimonial`}
					/>
					{isOpen && (
						<video
							ref={videoElement}
							controls={true}
							name="media"
							preload="none"
							className="rounded-t-xl w-full absolute top-0 left-0"
							poster={
								thumbnail.gatsbyImageData.images.fallback.src
							}
							autoPlay={true}
						>
							<source
								src={video.file.url}
								type={video.file.contentType}
							/>
							Your browser does not support the video tag.
						</video>
					)}
				</div>
				{AfterImageComponent && <AfterImageComponent />}
				{(projectReference || slug) && (
					<Link
						className="bg-white p-3 text-base text-gray-800 text-center md:mb-0 font-semibold flex items-center hover:text-gvd-green justify-center z-10 relative leading-none rounded-xl"
						to={
							'/projects/' +
							(projectReference && projectReference.slug
								? projectReference.slug +
								  (projectReference.slug[
										projectReference.slug.length - 1
								  ] !== '/'
										? '/'
										: '')
								: slug +
								  (slug[slug.length - 1] !== '/' ? '/' : ''))
						}
					>
						<span>View Project Photos</span>{' '}
						<ChevronRight size={'18'} strokeWidth={'3'} />
					</Link>
				)}
			</div>
		</div>
	);
};
